import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import AdminApp from 'components/AdminApp';
import MainApp from 'components/MainApp';
import UserApp from 'components/UserApp';

import Users from './Users';
import Hospitals from './Hospitals';
import Policies from './Policies';
import Login from './Login';
import Signup from './Signup';
import CompleteRegistration from './CompleteRegistration';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import Logout from './Logout';
import Home from './Home';
import GlobalSection from './GlobalSection';
import FooterSection from './FooterSection';

const Routes = () => {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Switch>
          <Route exact path="/logout" component={Logout} />
          <Route path="/admin">
            <AdminApp>
              <Switch>
                <Route exact path="/admin/global-section" component={GlobalSection} />
                <Route exact path="/admin/footer-section" component={FooterSection} />
                <Route path="/admin/users" component={Users} />
                <Route path="/admin/hospitals" component={Hospitals} />
                <Route path="/admin/policies" component={Policies} />
                <Redirect path="/admin" to="/admin/global-section" />
              </Switch>
            </AdminApp>
          </Route>
          <Route path="/">
            <MainApp>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/complete-registration" component={CompleteRegistration} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <UserApp>
                  <Route exact path="/" component={Home} />
                  <Route
                    exact path="/view/intertrauma-policies/policies-and-procedures/:policyName"
                    render={props => (
                      <Redirect to={`/policies-and-procedures/${props.match.params.policyName}`} />
                    )}
                  />
                  <Route
                    exact path="/view/intertrauma-policies/policies-and-procedures/*/:policyName"
                    render={props => (
                      <Redirect to={`/policies-and-procedures/${props.match.params.policyName}`} />
                    )}
                  />
                  <Route exact path="/policies-and-procedures/:policyName" component={Home} />
                </UserApp>
              </Switch>
            </MainApp>
          </Route>
        </Switch>
      </LastLocationProvider>
    </BrowserRouter>
  )
};

export default Routes;
