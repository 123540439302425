import gql from 'graphql-tag';

export const SIGNUP = gql`
  mutation($email: String) {
    signup(email: $email) {
      error
      user {
        id
        first_name
        last_name
        email
        hospitals
      }
    }
  }
`;

export const COMPLETE_REGISTRATION = gql`
  mutation($first_name: String, $last_name: String, $password: String, $code: String) {
    completeRegistration(first_name: $first_name, last_name: $last_name, password: $password, code: $code) {
      error
      user {
        id
        first_name
        last_name
        email
        hospitals
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation($password: String, $code: String) {
    resetPassword(password: $password, code: $code) {
      error
      user {
        id
        first_name
        last_name
        email
        hospitals
      }
    }
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation($email: String) {
    sendResetPasswordLink(email: $email) {
      error
      user {
        id
        first_name
        last_name
        email
        hospitals
      }
    }
  }
`;

export const LOGIN = gql`
  mutation($email: String, $password: String) {
    login(email: $email, password: $password) {
      error
      token
      user {
        id
        first_name
        last_name
        email
        hospitals
        policies {
          id
          name
          heading
          html
          content
        }
      }
    }
  }
`;

export const FETCH_ME = gql`
  {
    fetchMe {
      id
      first_name
      last_name
      email
      hospitals {
        id
        name
        heading
        html
        content
      }
      policies {
        id
        name
        heading
        html
        content
      }
      globalUser {
        html
        content
        footer_html
        footer_content
      }
    }
  }
`;
