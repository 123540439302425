import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Col, Row, Input, Spin, Typography } from 'antd';

import AuthForm from 'components/AuthForm';
import { LOGIN } from 'queries/auth';

const { Text } = Typography;

const Login = () => {
  const [user, setUser] = useState({});
  const [login, { data, loading }] = useMutation(LOGIN, {
    variables: {
      ...user
    }
  });

  if (data?.login?.token) {
    localStorage.setItem('tokenInfo', JSON.stringify(data?.login));
    if (data?.login?.user?.email === 'admin@intertrauma.com')
      return <Redirect to='/admin' />
    return <Redirect to='/' />
  }

  const onChangeHandler = field => e => {
    setUser({
      ...user,
      [field]: e.target.value
    });
  };

  return (
    <Spin spinning={loading}>
      <AuthForm onFinish={login}>
        <Row gutter={[10, 10]} justify="center">
          <Col span={24}>
            <Text type="danger">{data?.login?.error}</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please enter email' }]}
            >
              <Input
                placeholder="Please enter email"
                onChange={onChangeHandler('email')}
                type="email"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please enter password' }]}
            >
              <Input.Password
                placeholder="Please enter password"
                onChange={onChangeHandler('password')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading} size="large">
                Login
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" className="following">
          <Col>
            <Link to="/signup">
              No account yet? Create now
            </Link>
          </Col>
          <Col>
            <Link to="/forgot-password">
              Forgot password?
            </Link>
          </Col>
        </Row>
      </AuthForm>
    </Spin>
  );
};

export default Login;
