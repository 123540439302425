import React, { useEffect, useState } from 'react';
import { Button, Table, Popconfirm, Spin, Row, Col, Tag } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Route, Link } from 'react-router-dom';

import { LIST_USERS } from 'queries/users';
import { LIST_POLICIES, REMOVE_POLICY } from 'queries/policies';

import EditPolicy from './EditPolicy';

const Policies = ({ history }) => {
  const { data: policies, loading, refetch } = useQuery(LIST_POLICIES);
  const { data: users } = useQuery(LIST_USERS);
  const [removePolicy, { loading: removing }] = useMutation(REMOVE_POLICY, {
    onCompleted: (policy) => {
      onRemove(policy.removePolicy);
    }
  });

  const [data, setData] = useState([]);
  const handleNew = () => history.push('/admin/policies/new');

  const onUpdate = (updated, isNew) => {
    if (!updated || updated.__typename !== 'Policy') return;

    refetch();
    if (isNew) {
      setData([...data, updated]);
    } else {
      updated = policies?.listPolicies.find(policy => policy?.id === updated?.id)
      setData(data.map(policy => policy.id === updated.id ? updated : policy));
    }
  };
  const onRemove = (removed) => {
    setData(data.filter(({ id }) => id !== removed.id));
  };

  useEffect(() => {
    refetch();
    setData(policies?.listPolicies || []);
  }, [policies])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, { id }) => <Link to={`/admin/policies/${id}`}>{text}</Link>
    },
    {
      title: 'Heading',
      dataIndex: 'heading'
    },
    {
      title: 'Employee Types',
      dataIndex: 'employee_types',
      render: (text, record) => (
        <Row>
          {record.employee_types?.map((employeeType) => {
            return (
              <Col key={employeeType?.id}>
                <Tag color="orange">{employeeType?.name}</Tag>
              </Col>
            );
          })}
        </Row>
      )
    },
    {
      title: '',
      width: 100,
      dataIndex: 'operation',
      render: (text, { id }) => (
        <Row justify="space-around">
          <Col>
            <Popconfirm title="Sure to delete?" onConfirm={() => removePolicy({ variables: { id } })}>
              <Link to="#"><DeleteOutlined /></Link>
            </Popconfirm>
          </Col>
          <Col>
            <Link to={`/admin/policies/${id}`}>
              <EditOutlined />
            </Link>
          </Col>
        </Row>
      )
    },
  ];

  return (
    <Spin spinning={loading || removing}>
      <Button
        onClick={handleNew}
        type="primary"
      >
        <PlusOutlined /> New policy
      </Button>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        rowKey="id"
      />
      <Route
        path="/admin/policies/:policyId"
        component={({ match }) => !loading ? (
          <EditPolicy
            history={history}
            onUpdate={onUpdate}
            defaultPolicy={data?.find(({ id }) => `${id}` === match?.params?.policyId)}
            policyId={match?.params?.policyId}
            globalUser={users?.listUsers?.find(({ id }) => !id)}
          />
        ) : null}
      />
    </Spin>
  );
};

export default Policies;
