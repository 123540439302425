import gql from 'graphql-tag';

export const LIST_HOSPITALS = gql`
  {
    listHospitals {
      id
      name
      heading
      html
      content
    }
  }
`;

export const CREATE_HOSPITAL = gql`
  mutation($hospital: HospitalInput!) {
    createHospital(hospital: $hospital) {
      id
      name
      heading
      html
      content
    }
  }
`;

export const UPDATE_HOSPITAL = gql`
  mutation($id: Int!, $hospital: HospitalInput!) {
    updateHospital(id: $id, hospital: $hospital) {
      id
      name
      heading
      html
      content
    }
  }
`;

export const REMOVE_HOSPITAL = gql`
  mutation($id: Int!) {
    removeHospital(id: $id) {
      id
      name
      heading
      html
      content
    }
  }
`;
