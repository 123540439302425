import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';

const UserApp = ({ children, history }) => {
  const tokenInfo = localStorage.getItem('tokenInfo') ? JSON.parse(localStorage.getItem('tokenInfo')) : {};
  if (!tokenInfo?.user?.email) return <Redirect to="/login" />;
  if (tokenInfo?.user?.email === 'admin@intertrauma.com') return <Redirect to="/admin" />;
  return (
    <>
      <LogoutOutlined className="logout-icon" onClick={() => history.push('/logout')} />
      {children}
    </>
  );
};

export default withRouter(UserApp);
