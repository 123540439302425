import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from '@apollo/react-hooks';

import logo from 'assets/images/logo.jpg';
import adBanner from 'assets/images/ad-banner.jpg';
import Footer from 'components/Footer';
import { FETCH_ME } from 'queries/auth';

import './index.scss';

const MainApp = ({ children, globalUser }) => {
  const { data, loading } = useQuery(FETCH_ME, {
    fetchPolicy: 'no-cache'
  });
  const [showAd, setShowAd] = useState(true);
  return (
    <Row className="main-app">
      <Col className={`banner ${showAd ? 'active' : ''}`}>
        <a href="http://www.mtpscorekeeper.com/" target="new">
          <img src={adBanner} className="ad-banner" alt="MTPScoreKeeper" />
        </a>
        <span className="close" onClick={() => setShowAd(false)}>×</span>
      </Col>
      <Col xs={24}>
        <a href="https://www.intertrauma.com/">
          <img src={logo} className="logo" alt="InterTrauma" />
        </a>
      </Col>
      <Col xs={24}>
        {children}
      </Col>
      <Col span={24}>
        <Footer globalUser={globalUser || data?.fetchMe?.globalUser} user={data?.fetchMe} />
      </Col>
    </Row>
  )
};

export default MainApp;
