import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button, Drawer, Form, Col, Row, Input, Spin, Select } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { pick } from 'lodash';
import EmailEditor from 'react-email-editor';

import MainApp from 'components/MainApp';
import HomeComponent from 'components/HomeComponent';
import { CREATE_POLICY, UPDATE_POLICY } from 'queries/policies';
import { LIST_EMPLOYEE_TYPES } from 'queries/employee_types';

let editorHtml = '';
let editorContent = null;

const normalizeHtml = html => {
  const tmp = '<table' + html.split('<table').slice(1).join('<table');
  return tmp.split('/table>').reverse().slice(1).reverse().join('/table>') + '/table>';
};

const EditPolicy = ({ history, defaultPolicy, globalUser, policyId, onUpdate }) => {
  const emailEditorRef = useRef(null);
  const lastLocation = useLastLocation();
  const isNew = policyId === 'new';
  const [policy, setPolicy] = useState(defaultPolicy);
  const [preview, setPreview] = useState(false);
  const [previewHtml, setPreviewHtml] = useState('');
  const [employeeTypeIds, setEmployeeTypeIds] = useState([]);
  const [updateEmployeeIds, setUpdateEmployeeIds] = useState(false);
  const INITIAL_EMPLOYEE_TYPE_IDS = policy?.employee_types?.map((et)=>{return et.id}) || []
  const useInitialEmployeeIds = !isNew && !employeeTypeIds.length && !updateEmployeeIds
  const { data: employee_types } = useQuery(LIST_EMPLOYEE_TYPES);

  const [createPolicy, { loading: creating }] = useMutation(CREATE_POLICY, {
    variables: {
      policy: {
        ...policy,
        html: editorHtml,
        content: editorContent,
      },
      employee_type_ids: employeeTypeIds
    },
    onCompleted: (policy) => {
      onClose(policy.createPolicy?.id && policy.createPolicy, true);
    }
  });


  const [updatePolicy, { loading: updating }] = useMutation(UPDATE_POLICY, {
    variables: {
      id: policy?.id,
      policy: {
        ...pick(policy, ['name', 'heading']),
        html: editorHtml,
        content: editorContent
      },
      employee_type_ids: employeeTypeIds
    },
    onCompleted: (policy) => {
      onClose(policy.updatePolicy);
    }
  });

  const onClose = (policy, isNew = false) => {
    onUpdate(policy, isNew);
    if (lastLocation?.pathname === '/admin/policies')
      history.goBack();
    else
      history.replace('/admin/policies');
  };

  const onChangeHandler = field => e => {
    const value = e.target.value

    if (field === 'employee_type_ids') {
      setUpdateEmployeeIds(true)
      setEmployeeTypeIds(value.map(key => parseInt(key, 10)))
    } else {
      setPolicy({
        ...policy,
        [field]: value
      });
    }
  };

  const onLoad = () => {
    if (defaultPolicy?.content) {
      if (!emailEditorRef.current) {
        setTimeout(onLoad, 100);
        return;
      }
      emailEditorRef.current.editor.loadDesign(JSON.parse(defaultPolicy?.content));
    }
  };

  const onPreview = () => {
    if (preview) {
      setPreview(false);
      return;
    }
    if (!emailEditorRef.current) {
      setTimeout(onPreview, 100);
      return;
    }
    emailEditorRef.current.editor.exportHtml((data) => {
      const { html } = data;
      setPreview(!preview);
      setPreviewHtml(normalizeHtml(html));
    });
  };

  useEffect(() => {
    setPolicy(defaultPolicy);
  }, [defaultPolicy])

  const loading = creating || updating;

  const submit = (cb) => {
    if (useInitialEmployeeIds) {
      setEmployeeTypeIds(INITIAL_EMPLOYEE_TYPE_IDS);
    }

    const submitFunc = isNew ? createPolicy : updatePolicy;
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      editorHtml = normalizeHtml(html);
      editorContent = JSON.stringify(design);
      submitFunc();
    });
  };

  return (
    <Drawer
      title={isNew ? "Create a new policy" : "Edit a policy"}
      width="100%"
      onClose={onClose}
      visible
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onFinish={submit}
          initialValues={{...policy, employee_type_ids: INITIAL_EMPLOYEE_TYPE_IDS}}
        >
          {preview ? (
            <MainApp globalUser={globalUser}>
              <HomeComponent
                user={{
                  policies: [{ id: 1, html: previewHtml }]
                }}
                globalUser={globalUser}
                loading={false}
              />
            </MainApp>
          ) : (
            <Row gutter={[10, 10]} justify="end">
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: 'Please enter policy name' }]}
                >
                  <Input
                    placeholder="Please enter policy name"
                    onChange={onChangeHandler('name')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="heading"
                  label="Heading"
                >
                  <Input
                    placeholder="Please enter heading"
                    onChange={onChangeHandler('heading')}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="employee_type_ids"
                  label="Employee Type"
                >
                  <Select
                    mode="multiple"
                    size="default"
                    placeholder="Please select the employee types this policy belongs to"
                    onChange={value =>
                      onChangeHandler('employee_type_ids')({
                        target: {
                          value: value.map(key => parseInt(key, 10))
                        }
                      })
                    }
                  >
                    {employee_types?.listEmployeeTypes.map(({id, name}) => {
                      if (name !== "None") {
                        return (<Select.Option key={id} value={id}>{name}</Select.Option>)
                      }
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div style={{ display: preview ? 'none' : 'block' }}>
                  <EmailEditor
                    ref={emailEditorRef}
                    onLoad={onLoad}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={[10, 10]} justify="end">
            <Col>
              <Form.Item>
                <Button onClick={onPreview} style={{ marginRight: 100 }} disabled={loading} type="secondary">
                  {preview ? 'Edit' : 'Preview'}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={onClose} disabled={loading}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditPolicy;
