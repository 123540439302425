import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Col, Row, Input, Spin, Typography } from 'antd';
import queryString from 'query-string';

import AuthForm from 'components/AuthForm';
import { RESET_PASSWORD } from 'queries/auth';

const { Text } = Typography;

const ResetPassword = ({ history }) => {
  const { code } = queryString.parse(history.location.search);
  const [user, setUser] = useState({ code });
  const [resetPassword, { data, loading }] = useMutation(RESET_PASSWORD, {
    variables: {
      ...user
    }
  });

  const onChangeHandler = field => e => {
    setUser({
      ...user,
      [field]: e.target.value
    });
  };

  if (data?.resetPassword?.user?.id) {
    return (
      <AuthForm>
        <Row gutter={[10, 10]} justify="center">
          <Col span={24}>
            <h3>Password is reset successfully!</h3>
          </Col>
          <Col span={24}>
            <Link to="/login">
              Go to login
            </Link>
          </Col>
        </Row>
      </AuthForm>
    );
  }

  return (
    <Spin spinning={loading}>
      <AuthForm onFinish={resetPassword}>
        <Row gutter={[10, 10]} justify="center">
          <Col span={24}>
            <Text type="danger">{data?.resetPassword?.error}</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please enter your password' }]}
            >
              <Input
                placeholder="Create new password"
                onChange={onChangeHandler('password')}
                type="password"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading} size="large">
                Reset Password
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Link to="/login">
              Already have an account? Go to login.
            </Link>
          </Col>
        </Row>
      </AuthForm>
    </Spin>
  );
};

export default ResetPassword;
