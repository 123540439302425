import React from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { startCase } from 'lodash';

const Breadcrumb = ({ history }) => {
  const { location: { pathname } } = history;
  const items = pathname.split('?')[0].split('#')[0].split('/').filter(key => key);
  return (
    <AntdBreadcrumb style={{ margin: '16px 0' }}>
      {items.map((item, index) => (
        <AntdBreadcrumb.Item key={index}>{startCase(item)}</AntdBreadcrumb.Item>
      ))}
    </AntdBreadcrumb>
  )
};

export default Breadcrumb;
