import React from 'react';
import { Form } from 'antd';

import './index.scss';

const AuthForm = ({ children, className, ...props }) => (
  <Form
    layout="vertical"
    className={`${className} auth-form`}
    {...props}
  >
    {children}
  </Form>
);

export default AuthForm;
