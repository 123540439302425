import React from 'react';
import { Layout } from 'antd';

import logoAdmin from 'assets/images/logo-admin.png';

const Header = () => {
  return (
    <Layout.Header className="header">
      <img src={logoAdmin} className="logo" alt="InterTrauma" />
    </Layout.Header>
  )
};

export default Header;
