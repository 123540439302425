import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Col, Row, Input, Spin, Typography } from 'antd';
import queryString from 'query-string';

import AuthForm from 'components/AuthForm';
import { COMPLETE_REGISTRATION } from 'queries/auth';

const { Text } = Typography;

const CompleteRegistration = ({ history }) => {
  const { code } = queryString.parse(history.location.search);
  const [user, setUser] = useState({ code });
  const [completeRegistration, { data, loading }] = useMutation(COMPLETE_REGISTRATION, {
    variables: {
      ...user
    }
  });

  const onChangeHandler = field => e => {
    setUser({
      ...user,
      [field]: e.target.value
    });
  };

  if (data?.completeRegistration?.user?.id) {
    return (
      <AuthForm>
        <Row gutter={[10, 10]} justify="center">
          <Col span={24}>
            <h3>Your account is activated successfully!</h3>
          </Col>
          <Col span={24}>
            <Link to="/login">
              Go to login
            </Link>
          </Col>
        </Row>
      </AuthForm>
    );
  }

  return (
    <Spin spinning={loading}>
      <AuthForm onFinish={completeRegistration}>
        <Row gutter={[10, 10]} justify="center">
          <Col span={24}>
            <Text type="danger">{data?.completeRegistration?.error}</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please enter your password' }]}
            >
              <Input
                placeholder="Create new password"
                onChange={onChangeHandler('password')}
                type="password"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading} size="large">
                Complete Registration
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Link to="/login">
              Already have an account? Go to login.
            </Link>
          </Col>
        </Row>
      </AuthForm>
    </Spin>
  );
};

export default CompleteRegistration;
