import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button, Drawer, Form, Col, Row, Input, Spin, Select } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { pick } from 'lodash';

import MainApp from 'components/MainApp';
import HomeComponent from 'components/HomeComponent';
import { CREATE_USER, UPDATE_USER } from 'queries/users';
import { LIST_EMPLOYEE_TYPES } from 'queries/employee_types';

const EditUser = ({ history, defaultUser, userId, hospitals, globalUser, onUpdate }) => {
  const lastLocation = useLastLocation();
  const isNew = userId === 'new';
  const [user, setUser] = useState(defaultUser);
  const [preview, setPreview] = useState(false);

  const [createUser, { loading: creating }] = useMutation(CREATE_USER, {
    variables: {
      user
    },
    onCompleted: (user) => {
      onClose(user.createUser?.id && user.createUser, true);
    }
  });

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER, {
    variables: {
      id: user?.id,
      user: pick(user, ['first_name', 'last_name', 'email', 'hospitals', 'employee_type_id'])
    },
    onCompleted: (user) => {
      onClose(user.updateUser);
    }
  });

  const onClose = (user, isNew = false) => {
    onUpdate(user, isNew);
    history.replace('/admin/users');
  }

  const onChangeHandler = field => e => {
    setUser({
      ...user,
      [field]: e.target.value
    });
  };

  useEffect(() => {
    setUser(defaultUser);
  }, [defaultUser])

  const loading = creating || updating;
  const submit = isNew ? createUser : updateUser;

  const onPreview = () => {
    setPreview(!preview);
  };

  const { data: employee_types } = useQuery(LIST_EMPLOYEE_TYPES);

  return (
    <Drawer
      title={isNew ? "Create a new user" : "Edit a user"}
      width="100%"
      onClose={onClose}
      visible
    >
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={submit} initialValues={{ ...user, hospitals: user?.hospitals || [], employee_type_id: user?.employee_type_id || "None" }}>
          {preview ? (
            <MainApp globalUser={globalUser}>
              <HomeComponent
                user={{
                  ...user,
                  hospitals: user?.hospitals?.map(hospitalId =>
                    hospitals.find(({ id }) => id === hospitalId)
                  )
                }}
                globalUser={globalUser}
                loading={false}
              />
            </MainApp>
          ) : (
            <Row gutter={[10, 10]} justify="end">
              <Col span={12}>
                <Form.Item
                  name="first_name"
                  label="First Name"
                  rules={[{ required: true, message: 'Please enter the first name' }]}
                >
                  <Input
                    placeholder="Please enter the first name"
                    onChange={onChangeHandler('first_name')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="last_name"
                  label="Last Name"
                  rules={[{ required: true, message: 'Please enter the first name' }]}
                >
                  <Input
                    placeholder="Please enter the last name"
                    onChange={onChangeHandler('last_name')}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[{ required: true, message: 'Please enter the email address' }]}
                >
                  <Input
                    placeholder="Please enter the email address"
                    onChange={onChangeHandler('email')}
                    type="email"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="hospitals"
                  label="Hospitals"
                >
                  <Select
                    mode="multiple"
                    size="default"
                    placeholder="Please select hospitals"
                    onChange={value =>
                      onChangeHandler('hospitals')({
                        target: {
                          value: value.map(key => parseInt(key, 10))
                        }
                      })
                    }
                  >
                    {hospitals?.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>{name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="employee_type_id"
                  label="Employee Type"
                >
                  <Select
                    size="default"
                    onChange={value =>
                      onChangeHandler('employee_type_id')({
                        target: {
                          value: parseInt(value, 10)
                        }
                      })
                    }
                  >
                    {employee_types?.listEmployeeTypes.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>{name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={[10, 10]} justify="end">
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Form.Item>
                    <Button onClick={onPreview} style={{ marginRight: 100 }} disabled={loading} type="secondary">
                      {preview ? 'Edit' : 'Preview'}
                    </Button>
                  </Form.Item>
                </Col>
                <Row>
                  <Col>
                    <Form.Item>
                      <Button onClick={onClose} style={{ marginRight: 8 }} disabled={loading}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" disabled={loading}>
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

EditUser.defaultProps = {
  hospitals: [],
  employee_types: []
};

export default EditUser;
