import React from 'react';

import './index.scss';

const CommonBlock = ({ hasLogo, globalUser, user }) => {
  let html = globalUser?.html;
  if (user?.id) {
    html = html?.split('{first_name}').join(user?.first_name).split('{last_name}').join(user?.last_name);
  }
  return (
    <div className="common-block">
      {hasLogo && (
        <img
          src="//static1.squarespace.com/static/563fa772e4b093dffbdb0548/t/5c87d200f4e1fce73352dcf1/1595436513060/?format=1500w"
          alt="InterTrauma Consulting - Premium Surgical Staffing"
          className="logo"
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default CommonBlock;
