import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Col, Row, Input, Spin, Typography } from 'antd';

import AuthForm from 'components/AuthForm';
import { SIGNUP } from 'queries/auth';

const { Text } = Typography;

const Signup = () => {
  const [user, setUser] = useState({});
  const [signup, { data, loading }] = useMutation(SIGNUP, {
    variables: {
      ...user
    }
  });

  const onChangeHandler = field => e => {
    setUser({
      ...user,
      [field]: e.target.value
    });
  };

  if (data?.signup?.user?.id) {
    return (
      <AuthForm>
        <Row gutter={[10, 10]} justify="center">
          <Col span={24}>
            <h3>We have sent an activation link to your email address. Please check your inbox.</h3>
          </Col>
          <Col span={24}>
            <Link to="/login">
              Go to login
            </Link>
          </Col>
        </Row>
      </AuthForm>
    );
  }

  return (
    <Spin spinning={loading}>
      <AuthForm onFinish={signup}>
        <Row gutter={[10, 10]} justify="center">
          <Col span={24}>
            <Text type="danger">{data?.signup?.error}</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please enter your email address' }]}
            >
              <Input
                placeholder="Please enter your email address"
                onChange={onChangeHandler('email')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading} size="large">
                Signup
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Link to="/login">
              Already have an account? Go to login.
            </Link>
          </Col>
        </Row>
      </AuthForm>
    </Spin>
  );
};

export default Signup;
