import React, { useEffect, useState } from 'react';
import { Button, Table, Popconfirm, Spin, Row, Col, Tag } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Route, Link } from 'react-router-dom';

import { LIST_USERS, REMOVE_USER } from 'queries/users';
import { LIST_HOSPITALS } from 'queries/hospitals';
import { LIST_EMPLOYEE_TYPES } from 'queries/employee_types';

import EditUser from './EditUser';

const Users = ({ history }) => {
  const { data: users, loading, refetch } = useQuery(LIST_USERS);
  const { data: hospitals, loading: loadingHospitals } = useQuery(LIST_HOSPITALS);
  const { data: employee_types, loading: loadingEmployeeTypes } = useQuery(LIST_EMPLOYEE_TYPES);
  const [removeUser, { loading: removing }] = useMutation(REMOVE_USER, {
    onCompleted: (user) => {
      onRemove(user.removeUser);
    }
  });

  const [data, setData] = useState([]);
  const handleNew = () => history.push('/admin/users/new');

  const onUpdate = (updated, isNew) => {
    if (!updated) return;

    refetch();
    if (isNew) {
      setData([...data, updated]);
    } else {
      updated.employee_type = employee_types?.listEmployeeTypes?.find(({id}) => id === updated.employee_type_id)
      setData(data.map(user => user?.id === updated?.id ? updated : user));
    }
  };
  const onRemove = (removed) => {
    setData(data.filter(({ id }) => id !== removed.id));
  };
  useEffect(() => {
    refetch();
    setData(users?.listUsers || []);
  }, [users])

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name'
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, { id }) => <Link to={`/admin/users/${id}`}>{text}</Link>
    },
    {
      title: 'Hospitals',
      dataIndex: 'hospitals',
      render: (text, record) => (
        <Row>
          {record.hospitals?.map((hospitalId) => {
            const hospital = hospitals?.listHospitals?.find(({ id }) => id === hospitalId);
            return (
              <Col key={hospitalId}>
                <Tag color="orange">{hospital?.name}</Tag>
              </Col>
            );
          })}
        </Row>
      )
    },
    {
      title: 'Employee Type',
      dataIndex: 'employee_type_id',
      render: (text, record) => (
        <Col key={record.id}>
          <Tag color="orange">{record.employee_type?.name}</Tag>
        </Col>
      )
    },
    {
      title: '',
      width: 100,
      dataIndex: 'operation',
      render: (text, { id }) => (
        <Row justify="space-around">
          <Col>
            <Popconfirm title="Sure to delete?" onConfirm={() => removeUser({ variables: { id } })}>
              <Link to="#"><DeleteOutlined /></Link>
            </Popconfirm>
          </Col>
          <Col>
            <Link to={`/admin/users/${id}`}>
              <EditOutlined />
            </Link>
          </Col>
        </Row>
      )
    },
  ];

  return (
    <Spin spinning={loading || loadingHospitals || loadingEmployeeTypes || removing}>
      <Button
        onClick={handleNew}
        type="primary"
      >
        <PlusOutlined /> New user
      </Button>
      <Table
        bordered
        dataSource={data.filter(({ id }) => id)}
        columns={columns}
        rowKey="id"
      />
      <Route
        path="/admin/users/:userId"
        component={({ match }) => !loading && !loadingHospitals && !loadingEmployeeTypes ? (
          <EditUser
            history={history}
            onUpdate={onUpdate}
            defaultUser={data?.find(({ id }) => `${id}` === match?.params?.userId)}
            hospitals={hospitals?.listHospitals}
            employee_types={employee_types?.listEmployeetypes}
            userId={match?.params?.userId}
            globalUser={data?.find(({ id }) => !id)}
          />
        ) : null}
      />
    </Spin>
  );
};

export default Users;
