import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import './index.scss';

const { Panel } = Collapse;

const PolicyBlock = ({ children, index, policy }) => (
  <Collapse key={policy?.id} defaultActiveKey={['0']}>
    <Panel className="policy-block" header={policy?.name} key={index}>
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </Panel>
  </Collapse>
);

PolicyBlock.propTypes = {
  children: PropTypes.string
};

export default PolicyBlock;
