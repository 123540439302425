import gql from 'graphql-tag';

export const LIST_EMPLOYEE_TYPES = gql`
  {
    listEmployeeTypes {
      id
      name
    }
  }
`;
