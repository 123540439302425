import gql from 'graphql-tag';

export const LIST_USERS = gql`
  {
    listUsers {
      id
      first_name
      last_name
      email
      hospitals
      employee_type_id
      html
      content
      footer_html
      footer_content
      employee_type {
        id
        name
      }
      policies {
        id
        name
        heading
        html
        content
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation($user: UserInput!) {
    createUser(user: $user) {
      id
      first_name
      last_name
      email
      hospitals
      employee_type_id
      html
      content
      footer_html
      footer_content
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($id: Int!, $user: UserInput!) {
    updateUser(id: $id, user: $user) {
      id
      first_name
      last_name
      email
      hospitals
      employee_type_id
      html
      content
      footer_html
      footer_content
    }
  }
`;

export const REMOVE_USER = gql`
  mutation($id: Int!) {
    removeUser(id: $id) {
      id
      first_name
      last_name
      email
      hospitals
      employee_type_id
      html
      content
      footer_html
      footer_content
    }
  }
`;
