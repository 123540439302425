import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  ReconciliationOutlined,
  TeamOutlined,
  LogoutOutlined,
  PicLeftOutlined,
  PicRightOutlined
} from '@ant-design/icons';

const { Sider } = Layout;

const menus = [
  { key: 'admin/global-section', label: 'Global Section', Icon: PicLeftOutlined },
  { key: 'admin/footer-section', label: 'Footer Section', Icon: PicRightOutlined },
  { key: 'admin/users', label: 'Users', Icon: TeamOutlined },
  { key: 'admin/hospitals', label: 'Hospitals', Icon: ReconciliationOutlined },
  { key: 'admin/policies', label: 'Policies', Icon: ReconciliationOutlined },
  { key: 'logout', label: 'Logout', Icon: LogoutOutlined }
];

const Sidebar = ({ history }) => {
  const [collapsed, setCollapsed] = useState(false);
  const handleClick = ({ key }) => history.push(`/${key}`);
  const currentSelectedMenu = menus.find(({ key }) => history.location.pathname.startsWith(`/${key}`)) || {};
  return (
    <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} className="sidebar">
      <Menu selectedKeys={[currentSelectedMenu.key]} theme="dark" mode="inline" onClick={handleClick}>
        {
          menus.map(({ key, label, Icon }) => (
            <Menu.Item key={key} icon={<Icon />} className="menu-item">{label}</Menu.Item>
          ))
        }
      </Menu>
    </Sider>
  );
};

export default Sidebar;
