import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import HomeComponent from 'components/HomeComponent';
import { FETCH_ME } from 'queries/auth';
import { LIST_POLICIES } from 'queries/policies';

const Home = () => {
  const { data, loading: loadingUser } = useQuery(FETCH_ME, {
    fetchPolicy: 'no-cache'
  });
  const { data: policies, loading: loadingPolicies } = useQuery(LIST_POLICIES);
  return (
    <HomeComponent
      user={data?.fetchMe}
      allPolicies={policies?.listPolicies}
      globalUser={data?.fetchMe?.globalUser}
      loading={loadingUser && loadingPolicies}
    />
  );
};

export default Home;
