import ApolloClient from 'apollo-boost';
import { API_URL } from 'config';

const client = new ApolloClient({
  uri: `${API_URL}/graphql`,
  request: ({ setContext }) => {
    setContext({
      headers: {
        authorization:
          JSON.parse(localStorage.getItem('tokenInfo') || '{}').token || ''
      }
    });
  },
  defaultOptions: {
    fetchPolicy: 'no-cache'
  }
});

export default client;
