import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import Favicon from 'react-favicon';

import favIcon from 'assets/images/favicon.jpeg';
import apolloClient from 'config/createApolloClient';

import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Favicon url={favIcon} />
    <Routes />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
