import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const HospitalBlock = ({ children }) => (
  <div className="hospital-block" dangerouslySetInnerHTML={{ __html: children }} />
);

HospitalBlock.propTypes = {
  children: PropTypes.string
};

export default HospitalBlock;
