import React from 'react';

import './index.scss';

const Footer = ({ globalUser, user }) => {
  let html = globalUser?.footer_html;
  if (user?.id) {
    html = html?.split('{first_name}').join(user?.first_name).split('{last_name}').join(user?.last_name);
  }
  return (
    <div className="footer">
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default Footer;
