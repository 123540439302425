import gql from 'graphql-tag';

export const LIST_POLICIES = gql`
  {
    listPolicies {
      id
      name
      heading
      html
      content
      employee_types {
        id
        name
      }
    }
  }
`;

export const CREATE_POLICY = gql`
  mutation($policy: PolicyInput!, $employee_type_ids: [Int]) {
    createPolicy(policy: $policy, employee_type_ids: $employee_type_ids) {
      id
      name
      heading
      html
      content
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation($id: Int!, $policy: PolicyInput!, $employee_type_ids: [Int]!) {
    updatePolicy(id: $id, policy: $policy, employee_type_ids: $employee_type_ids) {
      id
      name
      heading
      html
      content
    }
  }
`;

export const REMOVE_POLICY = gql`
  mutation($id: Int!) {
    removePolicy(id: $id) {
      id
      name
      heading
      html
      content
    }
  }
`;
