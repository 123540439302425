import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Form, Col, Row, Spin } from 'antd';
import EmailEditor from 'react-email-editor';

import CommonBlock from 'components/CommonBlock';
import { LIST_USERS, UPDATE_USER } from 'queries/users';

let editorHtml = '';
let editorContent = null;

const normalizeHtml = html => {
  const tmp = '<table' + html.split('<table').slice(1).join('<table');
  return tmp.split('/table>').reverse().slice(1).reverse().join('/table>') + '/table>';
};

const GlobalSection = () => {
  const emailEditorRef = useRef(null);
  const [preview, setPreview] = useState(false);
  const [previewHtml, setPreviewHtml] = useState('');

  const { data: users, loading: listLoading } = useQuery(LIST_USERS);

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER, {
    variables: {
      id: 0,
      user: {
        html: editorHtml,
        content: editorContent
      }
    }
  });

  const globalUser = users?.listUsers?.find(({ id }) => !id);

  const onLoad = () => {
    if (globalUser?.content) {
      if (!emailEditorRef.current) {
        setTimeout(onLoad, 100);
        return;
      }
      emailEditorRef.current.editor.loadDesign(JSON.parse(globalUser?.content));
    }
  };

  const onPreview = () => {
    if (!emailEditorRef.current) {
      setTimeout(onPreview, 100);
      return;
    }
    if (preview) {
      setPreview(false);
      return;
    }
    emailEditorRef.current.editor.exportHtml((data) => {
      const { html } = data;
      setPreview(!preview);
      setPreviewHtml(normalizeHtml(html));
    });
  };

  const submit = (cb) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      editorHtml = normalizeHtml(html);
      editorContent = JSON.stringify(design);
      updateUser();
    });
  };

  const loading = listLoading || updating;

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        onFinish={submit}
        initialValues={globalUser}
      >
        <Row gutter={[10, 10]} justify="end">
          {globalUser && (
            <Col span={24}>
              {preview && (
                <CommonBlock hasLogo globalUser={{ html: previewHtml }} />
              )}
              <div style={{ display: preview ? 'none' : 'block' }}>
                <EmailEditor
                  ref={emailEditorRef}
                  onLoad={onLoad}
                />
              </div>
            </Col>
          )}
          <Col>
            <Form.Item>
              <Button onClick={onPreview} disabled={loading} type="secondary">
                {preview ? 'Edit' : 'Preview'}
              </Button>
            </Form.Item>
          </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default GlobalSection;
