import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Drawer, Form, Col, Row, Input, Spin } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { pick } from 'lodash';
import EmailEditor from 'react-email-editor';

import MainApp from 'components/MainApp';
import HomeComponent from 'components/HomeComponent';
import { CREATE_HOSPITAL, UPDATE_HOSPITAL } from 'queries/hospitals';

let editorHtml = '';
let editorContent = null;

const normalizeHtml = html => {
  const tmp = '<table' + html.split('<table').slice(1).join('<table');
  return tmp.split('/table>').reverse().slice(1).reverse().join('/table>') + '/table>';
};

const EditHospital = ({ history, defaultHospital, globalUser, hospitalId, onUpdate }) => {
  const emailEditorRef = useRef(null);
  const lastLocation = useLastLocation();
  const isNew = hospitalId === 'new';
  const [hospital, setHospital] = useState(defaultHospital);
  const [preview, setPreview] = useState(false);
  const [previewHtml, setPreviewHtml] = useState('');

  const [createHospital, { loading: creating }] = useMutation(CREATE_HOSPITAL, {
    variables: {
      hospital: {
        ...hospital,
        html: editorHtml,
        content: editorContent
      }
    },
    onCompleted: (hospital) => {
      onClose(hospital.createHospital?.id && hospital.createHospital, true);
    }
  });

  const [updateHospital, { loading: updating }] = useMutation(UPDATE_HOSPITAL, {
    variables: {
      id: hospital?.id,
      hospital: {
        ...pick(hospital, ['name', 'heading']),
        html: editorHtml,
        content: editorContent
      }
    },
    onCompleted: (hospital) => {
      onClose(hospital.updateHospital);
    }
  });

  const onClose = (hospital, isNew = false) => {
    onUpdate(hospital, isNew);
    if (lastLocation?.pathname === '/admin/hospitals')
      history.goBack();
    else
      history.replace('/admin/hospitals');
  };

  const onChangeHandler = field => e => {
    setHospital({
      ...hospital,
      [field]: e.target.value
    });
  };

  const onLoad = () => {
    if (defaultHospital?.content) {
      if (!emailEditorRef.current) {
        setTimeout(onLoad, 100);
        return;
      }
      emailEditorRef.current.editor.loadDesign(JSON.parse(defaultHospital?.content));
    }
  };

  const onPreview = () => {
    if (preview) {
      setPreview(false);
      return;
    }
    if (!emailEditorRef.current) {
      setTimeout(onPreview, 100);
      return;
    }
    emailEditorRef.current.editor.exportHtml((data) => {
      const { html } = data;
      setPreview(!preview);
      setPreviewHtml(normalizeHtml(html));
    });
  };

  useEffect(() => {
    setHospital(defaultHospital);
  }, [defaultHospital])

  const loading = creating || updating;

  const submit = (cb) => {
    const submitFunc = isNew ? createHospital : updateHospital;
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      editorHtml = normalizeHtml(html);
      editorContent = JSON.stringify(design);
      submitFunc();
    });
  };

  return (
    <Drawer
      title={isNew ? "Create a new hospital" : "Edit a hospital"}
      width="100%"
      onClose={onClose}
      visible
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onFinish={submit}
          initialValues={hospital}
        >
          {preview ? (
            <MainApp globalUser={globalUser}>
              <HomeComponent
                user={{
                  hospitals: [{ id: 1, html: previewHtml }]
                }}
                globalUser={globalUser}
                loading={false}
              />
            </MainApp>
          ) : (
            <Row gutter={[10, 10]} justify="end">
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: 'Please enter hospital name' }]}
                >
                  <Input
                    placeholder="Please enter hospital name"
                    onChange={onChangeHandler('name')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="heading"
                  label="Heading"
                  rules={[{ required: true, message: 'Please enter heading' }]}
                >
                  <Input
                    placeholder="Please enter heading"
                    onChange={onChangeHandler('heading')}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div style={{ display: preview ? 'none' : 'block' }}>
                  <EmailEditor
                    ref={emailEditorRef}
                    onLoad={onLoad}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={[10, 10]} justify="end">
            <Col>
              <Form.Item>
                <Button onClick={onPreview} style={{ marginRight: 100 }} disabled={loading} type="secondary">
                  {preview ? 'Edit' : 'Preview'}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={onClose} disabled={loading}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditHospital;
