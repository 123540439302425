import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Layout, Card } from 'antd';

import Sidebar from './Sidebar';
import Header from './Header';
import Breadcrumb from './Breadcrumb';
import './index.scss';

const { Content, Footer } = Layout;

const AdminApp = ({ children, history }) => {
  const tokenInfo = localStorage.getItem('tokenInfo') ? JSON.parse(localStorage.getItem('tokenInfo')) : {};
  if (tokenInfo?.user?.email !== 'admin@intertrauma.com') return <Redirect to="/login" />;
  return (
    <Layout className="app">
      <Header />
      <Layout hasSider>
        <Sidebar history={history} />
        <Layout>
          <Content className="content">
            <Breadcrumb history={history} />
            <Card className="main-content">
              {children}
            </Card>
          </Content>
          <Footer className="footer">InterTrauma Consulting ©2020</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default withRouter(AdminApp);
