import React, { useEffect, useState } from 'react';
import { Button, Table, Popconfirm, Spin, Row, Col } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Route, Link } from 'react-router-dom';

import { LIST_USERS } from 'queries/users';
import { LIST_HOSPITALS, REMOVE_HOSPITAL } from 'queries/hospitals';

import EditHospital from './EditHospital';

const Hospitals = ({ history }) => {
  const { data: hospitals, loading } = useQuery(LIST_HOSPITALS);
  const { data: users } = useQuery(LIST_USERS);
  const [removeHospital, { loading: removing }] = useMutation(REMOVE_HOSPITAL, {
    onCompleted: (hospital) => {
      onRemove(hospital.removeHospital);
    }
  });

  const [data, setData] = useState([]);
  const handleNew = () => history.push('/admin/hospitals/new');

  const onUpdate = (updated, isNew) => {
    if (!updated) return;
    if (isNew) {
      setData([...data, updated]);
    } else {
      setData(data.map(hospital => hospital.id === updated.id ? updated : hospital));
    }
  };
  const onRemove = (removed) => {
    setData(data.filter(({ id }) => id !== removed.id));
  };

  useEffect(() => {
    setData(hospitals?.listHospitals || []);
  }, [hospitals])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, { id }) => <Link to={`/admin/hospitals/${id}`}>{text}</Link>
    },
    {
      title: 'Heading',
      dataIndex: 'heading'
    },
    {
      title: '',
      width: 100,
      dataIndex: 'operation',
      render: (text, { id }) => (
        <Row justify="space-around">
          <Col>
            <Popconfirm title="Sure to delete?" onConfirm={() => removeHospital({ variables: { id } })}>
              <Link to="#"><DeleteOutlined /></Link>
            </Popconfirm>
          </Col>
          <Col>
            <Link to={`/admin/hospitals/${id}`}>
              <EditOutlined />
            </Link>
          </Col>
        </Row>
      )
    },
  ];

  return (
    <Spin spinning={loading || removing}>
      <Button
        onClick={handleNew}
        type="primary"
      >
        <PlusOutlined /> New hospital
      </Button>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        rowKey="id"
      />
      <Route
        path="/admin/hospitals/:hospitalId"
        component={({ match }) => !loading ? (
          <EditHospital
            history={history}
            onUpdate={onUpdate}
            defaultHospital={data?.find(({ id }) => `${id}` === match?.params?.hospitalId)}
            hospitalId={match?.params?.hospitalId}
            globalUser={users?.listUsers?.find(({ id }) => !id)}
          />
        ) : null}
      />
    </Spin>
  );
};

export default Hospitals;
