import React from 'react';
import { Row, Col, Spin } from 'antd';
import { useParams } from "react-router-dom";

import CommonBlock from 'components/CommonBlock';
import HospitalBlock from 'components/HospitalBlock';
import PolicyBlock from 'components/PolicyBlock';

const HomeComponent = ({ user, allPolicies, globalUser, hasLogo, loading }) => {
  let { policyName } = useParams();
  const pathName = window.location.pathname
  const basePath = pathName.slice(0, pathName.lastIndexOf('/'))

  if (basePath === "/policies-and-procedures") {
    let user_policies = user?.policies;
    let foundPolicy = allPolicies?.find(pol => pol.heading === policyName);

    return (
      <Spin spinning={loading} className="home">
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <CommonBlock hasLogo={hasLogo} globalUser={globalUser} user={user} />
          </Col>
        </Row>
        {policyName === "user-policies" ?
          user_policies?.map((policy, index) => {
            return (
              <PolicyBlock key={index} index={index} policy={policy}>{policy.html}</PolicyBlock>
            );
          }) :
          <PolicyBlock key={foundPolicy?.id} index={0} policy={foundPolicy}>{foundPolicy?.html}</PolicyBlock>
        }
      </Spin>
    )
  } else {
    return (
      <Spin spinning={loading} className="home">
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <CommonBlock hasLogo={hasLogo} globalUser={globalUser} user={user} />
          </Col>
          {user?.hospitals?.map(hospital => {
            return (
              <Col xs={24} md={12} key={hospital.id}>
                <HospitalBlock>{hospital.html}</HospitalBlock>
              </Col>
            );
          })}
        </Row>
      </Spin>
    );
  };
};

export default HomeComponent;
